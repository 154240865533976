import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//Create QUOTATION
/* Data required
    * quotationCode (string)   
    * clientId (string)
    * eventDate (date - YYYY-MM-DD)
    * eventTypeId (number)
    * eventDetails (string)
    * eventPlace (string)
    * guestQuantity (number)
    * items (array)
        * itemId (number)
        * quantity (number)
    * customItems (array)
        * name (string)
        * price (number) 
    * withDetails (boolean) 
*/
export const CreateQuotation = async (eventDetails, quotationCode, itemList, quotationWithDetails) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let items = []
        let customItems = []
        for(var i in itemList) {
            const item = itemList[i]
            //console.log(item, item.itemAdd)

            if(item.status === 'active') {
                items.push({
                    itemId: item.itemId,
                    description: item.description,
                    price: item.price,
                    quantity: item.itemAdd
                })
            } else if(item.status === 'custom') {
                customItems.push({
                    name: item.name,
                    description: item.description,
                    price: item.price,
                    quantity: item.itemAdd
                })
            }
        }

        //console.log(items, customItems)

        let body = {
            quotationCode: quotationCode.quotationCode,
            clientId: '' + eventDetails.customer.customerId,
            eventDate: eventDetails.eventDate,
            eventTypeId: eventDetails.eventType.typeId,
            eventDetails: eventDetails.eventDetails,
            eventPlace: eventDetails.eventPlace,
            guestQuantity: parseInt(eventDetails.guestQuantity),
            items: JSON.stringify(items),
            customItems: JSON.stringify(customItems),
            withDetails: quotationWithDetails
        }

        const response = await fetch (`${API.BASEURL}/quotations/createQuotation`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Create QUOTATION step1
/* Data required
    * quotationCode (string)   
    * clientId (string)
    * eventDate (date - YYYY-MM-DD)
    * eventTypeId (number)
    * eventDetails (string)
    * eventPlace (string)
    * guestQuantity (number) 
*/
export const CreateQuotationFirstStep = async (eventDetails, quotationCode) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        
        //console.log(items, customItems)

        let body = {
            quotationCode: quotationCode.quotationCode,
            clientId: '' + eventDetails.customer.customerId,
            eventDate: eventDetails.eventDate,
            eventTypeId: eventDetails.eventType.typeId,
            eventDetails: eventDetails.eventDetails,
            eventPlace: eventDetails.eventPlace,
            guestQuantity: parseInt(eventDetails.guestQuantity),
        }

        const response = await fetch(`${API.BASEURL}/quotations/createQuotationDraft`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        
        const jsonResponse = await response.json();
        
        if (jsonResponse.success) {
            return jsonResponse;
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message);
        }
        
       
    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}


//Update QUOTATION
/* Data required
    * quotationCode (string)   
    * clientId (string)
    * eventDate (date - YYYY-MM-DD)
    * eventTypeId (number)
    * eventDetails (string)
    * eventPlace (string)
    * guestQuantity (number)
    * items (array)
        * itemId (number)
        * quantity (number)
    * customItems (array)
        * name (string)
        * price (number)
    * withDetails (boolean)    
*/
export const UpdateQuotation = async (event, quotationCode, itemList, id, quotationWithDetails) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let items = []
        let customItems = []
        for(var i in itemList) {
            const item = itemList[i]
            //console.log(item, item.itemAdd)

            if(item.status === 'active') {
                items.push({
                    itemId: item.itemId,
                    description: item.description,
                    price: item.price,
                    quantity: item.itemAdd
                })
            } else if(item.status === 'custom') {
                customItems.push({
                    name: item.name,
                    description: item.description,
                    price: item.price,
                    quantity: item.itemAdd
                })
            }
        }

        //console.log(items, customItems)

        let body = {
            quotationCode: quotationCode.quotationCode,
            clientId: '' + event.customer.customerId,
            eventDate: event.eventDate,
            eventTypeId: event.eventType.typeId,
            eventDetails: event.eventDetails,
            eventPlace: event.eventPlace,
            guestQuantity: parseInt(event.guestQuantity),
            items: JSON.stringify(items),
            customItems: JSON.stringify(customItems),
            withDetails: quotationWithDetails
        }

        const response = await fetch (`${API.BASEURL}/quotations/updateQuotation/${id}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//DELETE QUOTATION
export const DeleteQuotation = async (quotationId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/deleteQuotation/${quotationId}`, {
            method: "DELETE",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Get QUOTATION DETAILS
export const GetQuotationDetails = async (quotationId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/getQuotationsDetails/${quotationId}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Get QUOTATION DETAILS
export const GetCustomerQuotation = async (customerId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/getCustomerQuotations/${customerId}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Get ALL QUOTATION
export const GetQuotations = async (query, page, pageSize, filterEvent='', filterStatus='', filterUser=0, filterDate='') => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    let body = {
        query,
        page,
        pageSize,
        filters: {
            filterEvent,
            filterStatus,
            filterDate,
            filterUser
        }
    }

    try {
        const response = await fetch (`${API.BASEURL}/quotations/getQuotations`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Aprove quotation
export const AproveQuotation = async (quotationId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations//aproveQuotation/${quotationId}`, {
            method: "PATCH",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//VERIFY CODE
export const VerifyCode = async (code) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            code: code
        }

        const response = await fetch (`${API.BASEURL}/quotations/verifyCode`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Get PDF quotation
export const GetQuotationPDF = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/file/quotation/${id}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Send PDF quotation
export const SendQuotationPDF = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/sendMail/${id}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Update quotation downloads
export const CountDownloads = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/quotations/countDownloads/${id}`, {
            method: "PATCH",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}